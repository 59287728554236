const Terms = () => {
  return (
    <div className={`p-4`}>
      <h1 className={`pt-5 mt-3 text-light pb-2`}>Terms And Conditions</h1>
      <p className={`text-light`} style={{ textAlign: "justify" }}>
        Introduction
        <br />
        These terms and conditions shall govern your use of our website. <br />{" "}
        By using our website, you accept these terms and conditions in full;
        accordingly, if you disagree with these terms and conditions or any part
        of these terms and conditions, you must not use our website. <br /> If
        you register with our website, submit any material to our website or use
        any of our website services, we will ask you to expressly agree to these
        terms and conditions. <br /> You must be at least 10 years of age to use
        our website; by using our website or agreeing to these terms and
        conditions, you warrant and represent to us that you are at least 10
        years of age.
        <br /> Our website uses cookies; by using our website or agreeing to
        these terms and conditions, you consent to our use of cookies in
        accordance with the terms of our privacy and cookies policy.
        <br />
        <br />
        Introduction
        <br />
        You may: view pages from our website in a web browser; download pages
        from our website for caching in a web browser; print pages from our
        website; stream audio and video files from our website; and use our
        website services by means of a web browser subject to the other
        provisions of these terms and conditions.
        <br /> Except as expressly permitted BY THE provisions of these terms
        and conditions, you must not download any material from our website or
        save any such material to your computer.
        <br /> You may only use our website for your own personal and business
        purposes, and you must not use our website for any other purposes.
        <br />
        Except as expressly permitted by these terms and conditions, you must
        not edit or otherwise modify any material on our website. <br />
        Unless you own or control the relevant rights in the material, you must
        not: republish material from our website (including republication on
        another website); sell, rent or sub-license material from our website;
        show any material from our website in public; exploit material from our
        website for a commercial purpose; or redistribute material from our
        website.
        <br /> We reserve the right to restrict access to areas of our website,
        or indeed our whole website, at our discretion; you must not circumvent
        or bypass, or attempt to circumvent or bypass, any access restriction
        measures on our website.
        <br />
        <br /> Acceptable use You must not:
        <br /> use our website in any way or take any action that causes, or may
        cause, damage to the website or impairment of the performance,
        availability or accessibility of the website; use our website in any way
        that is unlawful, illegal, fraudulent or harmful, or in connection with
        any unlawful, illegal, fraudulent or harmful purpose or activity; use
        our website to copy, store, host, transmit, send, use, publish or
        distribute any material which consists of (or is linked to) any spyware,
        computer virus, Trojan horse, worm, keystroke logger, rootkit or other
        malicious computer software; conduct any systematic or automated data
        collection activities (including without limitation scraping, data
        mining, data extraction and data harvesting) on or in relation to our
        website without our express written consent; access or otherwise
        interact with our website using any robot, spider or other automated
        means, except for the purpose of search engine indexing; use data
        collected from our website for any direct marketing activity (including
        without limitation email marketing, SMS marketing, telemarketing and
        direct mailing). <br />
        You must not use data collected from our website to contact individuals,
        companies or other persons or entities.
        <br /> You must ensure that all the information you supply to us through
        our website, or in relation to our website, is [true, accurate, current,
        complete and non-misleading]. <br />
        <br />
        Registration and accounts
        <br /> You may register for an account with our website by completing
        and submitting the account registration form on our website, and
        clicking on the verification link in the email that the website will
        send to you. <br />
        You must not allow any other person to use your account to access the
        website. <br />
        You must notify us in writing immediately if you become aware of any
        unauthorised use of your account. <br />
        You must not use any other person's account to access the website,
        unless you have that person's express permission to do so. <br />
        <br />
        User login details
        <br /> If you register for an account with our website, we will provide
        you with a user ID and password. <br />
        Your user ID must not be liable to mislead; you must not use your
        account or user ID for or in connection with the impersonation of any
        person. <br />
        You must keep your password confidential. <br />
        You must notify us in writing immediately if you become aware of any
        disclosure of your password. <br />
        You are responsible for any activity on our website arising out of any
        failure to keep your password confidential, and may be held liable for
        any losses arising out of such a failure. <br />
        <br />
        Cancellation and suspension of account
        <br /> You may: suspend your account; cancel your account; and/or edit
        your account details at any time in our sole discretion without notice
        or explanation. <br />
        You may cancel your account on our website using your account control
        panel on the website.
        <br />
        <br /> Your content: licence
        <br /> In these terms and conditions, "your content" means all works and
        materials (including without limitation text, graphics, images, audio
        material, video material, audio-visual material, scripts, software and
        files) that you submit to us or our website for storage or publication
        on, processing by, or transmission via, our website. <br />
        You grant to us a worldwide, irrevocable, non-exclusive, royalty-free
        licence to use, reproduce, store, adapt, publish, translate and
        distribute your content in any existing or future media. You hereby
        waive all your moral rights in your content to the maximum extent
        permitted by applicable law; and you warrant and represent that all
        other moral rights in your content have been waived to the maximum
        extent permitted by applicable law.
        <br /> You may edit your content to the extent permitted using the
        editing functionality made available on our website. <br />
        Without prejudice to our other rights under these terms and conditions,
        if you breach any provision of these terms and conditions in any way, or
        if we reasonably suspect that you have breached these terms and
        conditions in any way, we may delete, unpublish or edit any or all of
        your content. Your content: rules You warrant and represent that your
        content will comply with these terms and conditions. Your content must
        not be illegal or unlawful, must not infringe any person's legal rights,
        and must not be capable of giving rise to legal action against any
        person (in each case in any jurisdiction and under any applicable law).
        Your content, and the use of your content by us in accordance with these
        terms and conditions, must not: be libellous or maliciously false; be
        obscene or indecent; infringe any copyright, moral right, database
        right, trade mark right, design right, right in passing off, or other
        intellectual property right; infringe any right of confidence, right of
        privacy or right under data protection legislation; constitute negligent
        advice or contain any negligent statement; constitute an incitement to
        commit a crime, instructions for the commission of a crime or the
        promotion of criminal activity; be in contempt of any court, or in
        breach of any court order; be in breach of racial or religious hatred or
        discrimination legislation; be blasphemous; be in breach of official
        secrets legislation; be in breach of any contractual obligation owed to
        any person; depict violence in an explicit, graphic or gratuitous
        manner; be pornographic, lewd, suggestive or sexually explicit; be
        untrue, false, inaccurate or misleading; consist of or contain any
        instructions, advice or other information which may be acted upon and
        could, if acted upon, cause illness, injury or death, or any other loss
        or damage; constitute spam; be offensive, deceptive, fraudulent,
        threatening, abusive, harassing, antisocial, menacing, hateful,
        discriminatory or inflammatory; or cause annoyance, inconvenience or
        needless anxiety to any person. Limited warranties We do not warrant or
        represent: the completeness or accuracy of the information published on
        our website; that the material on the website is up to date; or that the
        website or any service on the website will remain available. We reserve
        the right to discontinue or alter any or all of our website services,
        and to stop publishing our website, at any time in our sole discretion
        without notice or explanation; and save to the extent expressly provided
        otherwise in these terms and conditions, you will not be entitled to any
        compensation or other payment upon the discontinuance or alteration of
        any website services, or if we stop publishing the website. To the
        maximum extent permitted by applicable law, we exclude all
        representations and warranties relating to the subject matter of these
        terms and conditions, our website and the use of our website. Breaches
        of these terms and conditions Without prejudice to our other rights
        under these terms and conditions, if you breach these terms and
        conditions in any way, or if we reasonably suspect that you have
        breached these terms and conditions in any way, we may: send you one or
        more formal warnings; temporarily suspend your access to our website;
        permanently prohibit you from accessing our website; block computers
        using your IP address from accessing our website; contact any or all of
        your internet service providers and request that they block your access
        to our website; commence legal action against you, whether for breach of
        contract or otherwise; and/or suspend or delete your account on our
        website. Where we suspend or prohibit or block your access to our
        website or a part of our website, you must not take any action to
        circumvent such suspension or prohibition or blocking (including without
        limitation creating and/or using a different account). Variation We may
        revise these terms and conditions from time to time. The revised terms
        and conditions shall apply to the use of our website from the date of
        publication of the revised terms and conditions on the website, and you
        hereby waive any right you may otherwise have to be notified of, or to
        consent to, revisions of these terms and conditions.] OR [We will give
        you written notice of any revision of these terms and conditions, and
        the revised terms and conditions will apply to the use of our website
        from the date that we give you such notice; if you do not agree to the
        revised terms and conditions, you must stop using our website. If you
        have given your express agreement to these terms and conditions, we will
        ask for your express agreement to any revision of these terms and
        conditions; and if you do not give your express agreement to the revised
        terms and conditions within such period as we may specify, we will
        disable or delete your account on the website, and you must stop using
        the website. Assignment You hereby agree that we may assign, transfer,
        sub-contract or otherwise deal with our rights and/or obligations under
        these terms and conditions. You may not without our prior written
        consent assign, transfer, subcontract or otherwise deal with any of your
        rights and/or obligations under these terms and conditions. Severability
        If a provision of these terms and conditions is determined by any court
        or other competent authority to be unlawful and/or unenforceable, the
        other provisions will continue in effect. If any unlawful and/or
        unenforceable provision of these terms and conditions would be lawful or
        enforceable if part of it were deleted, that part will be deemed to be
        deleted, and the rest of the provision will continue in effect. Third
        party rights A contract under these terms and conditions is for our
        benefit and your benefit, and is not intended to benefit or be
        enforceable by any third party. The exercise of the parties' rights
        under a contract under these terms and conditions is not subject to the
        consent of any third party. Entire agreement The terms and conditions,
        together with our privacy and cookies policy, shall constitute the
        entire agreement between you and us in relation to your use of our
        website and shall supersede all previous agreements between you and us
        in relation to your use of our website. Law and jurisdiction These terms
        and conditions shall be governed by and construed in accordance with
        Indian Law. Any disputes relating to these terms and conditions shall be
        subject to the exclusive jurisdiction of the courts of India.
        <br />
        <br />
        {/* Our details.
        <br />
        1. This website is owned and operated by International Developers'
        Community.
        <br /> 2. We are a registered college society/club of Netaji Subhas
        University of Technology.
        <br />
        3. Our principal place of business is at Netaji Subhas University of
        Technology, Sector-3, Dwarka, New Delhi. <br />
        4. You can contact us: using our website contact form; by telephone, on the
        contact number published on our website from time to time; or by email,
        using the email address published on our website from time to time. */}
      </p>
    </div>
  );
};

export default Terms;
