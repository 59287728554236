const RefundPolicy = () => {
  return (
    <div style={{height: '75vh'}} className={`p-4`}>
      <h1 className={`pt-5 mt-3 text-light pb-2`}>Refund Policy</h1>
      <p className={`text-light`} style={{textAlign: 'justify'}}>
        Thank you for shopping at DevComm. <br/>Please read this policy carefully.
        This is the Return and Refund Policy of DevComm. This Return and Refund
        Policy for Devcomm is powered
        by TermsFeed. 
        <br/><br/>Digital Products<br/> We do not issue refunds for digital
        products once the order is confirmed and the product is sent. We
        recommend contacting us for assistance if you experience any issues
        receiving or Accesing our products. 
        <br/><br/>
        Registrations <br />
        We do not issue refunds for registrations once it is confirmed. We recommend contacting us for assistance if you experience any issues.
        <br/><br/>
        Contact Us<br/> If you have any questions
        about our Returns and Refunds Policy, please contact us by email:
        nsut@devcomm.international
      </p>
    </div>
  );
};

export default RefundPolicy;
