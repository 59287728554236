const FameList = [
    {
        name: 'Naman Singh',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671198798/Devcomm/Wall%20of%20Fame/IMG_20221112_120130_-_Naman_Singh_yiyae2.jpg',
        company: 'Cure.fit',
        position: 'SDE Intern',
        // societyRole: 'Devcomm President, 2024',
    },
    {
        name: 'Dhruv Tewari',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671192015/Devcomm/Wall%20of%20Fame/Dhruv21_-_Dhruv_Tewari_g5eorw.jpg',
        company: 'Netaji Subhas University of Technology, Delhi',
        position: 'Research Intern',
        // societyRole: 'President',
    },
    {
        name: 'Aryaman Sharma',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671194556/Devcomm/Wall%20of%20Fame/IMG_9402_-_Aryaman_Sharma_f5cfrm.jpg',
        company: 'Vanderbilt University, USA',
        position: 'Research Intern @ SCOPE Lab',
        // societyRole: 'President',
    },
    {
        name: 'Sahil Chawla',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671195779/Devcomm/Wall%20of%20Fame/IMG_6550_-_Sahil_Chawla_wmq63v.jpg',
        company: 'Sprinklr',
        position: 'Product Engineer Intern',
        // societyRole: 'President',
    },
    {
        name: 'Nitin Solanki',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671195647/Devcomm/Wall%20of%20Fame/IMG_20221128_201405_-_Nitin_Solanki_i5cpip.jpg',
        company: 'Visa',
        position: 'SDE Intern',
        // societyRole: 'President',
    },
    {
        name: 'Avil Goel',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671199543/Devcomm/Wall%20of%20Fame/IMG-20220906-WA0490_-_Avil_Goel_kmxcbv.jpg',
        company: 'Microsoft',
        position: 'SWE Intern',
        // societyRole: 'President',
    },
    {
        name: 'Suraj Kumar ',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671195936/Devcomm/Wall%20of%20Fame/suraj_2_-_Suraj_Kumar_kkqrbq.jpg',
        company: 'Microsoft',
        position: 'SWE Intern',
        // societyRole: 'President',
    },
    {
        name: 'Divyansh Tyagi',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671192016/Devcomm/Wall%20of%20Fame/WhatsApp_Image_2022-11-28_at_21.07.55_-_Divyansh_Tyagi_mgqlrn.jpg',
        company: 'Microsoft',
        position: 'SWE Intern',
        // societyRole: 'President',
    },
    {
        name: 'Bhavy Rastogi',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671199127/Devcomm/Wall%20of%20Fame/IMG-20221031-WA0065_-_Bhavy_Rastogi_odspzo.jpg',
        company: 'Daphnis Labs, HyperSrot',
        position: 'Frontend Developer',
        // societyRole: 'President',
    },
    {
        name: 'Maanas Talwar',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671192017/Devcomm/Wall%20of%20Fame/Maanas_Talwar_-_Maanas_Talwar_fhbmbi.jpg',
        company: 'Microsoft',
        position: 'SWE Intern',
        // societyRole: 'President',
    },
    {
        name: 'Rhythm Narula',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671196109/Devcomm/Wall%20of%20Fame/EE94DCD4-607C-437B-8380-F0F8861656D8_-_Rhythm_Narula_ojiex6.jpg',
        company: 'Microsoft',
        position: 'SWE Intern'
    },
    {
        name: 'Mukund Maheshwari',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671192016/Devcomm/Wall%20of%20Fame/IMG_20210215_114132_2_-_MUKUND_MAHESHWARI_zhocg0.jpg',
        company: 'Google India',
        position: 'SWE Intern',
        // societyRole: 'President',
    }, 
    {
        name: 'Samarth Arora',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671192015/Devcomm/Wall%20of%20Fame/1657227385602_-_Samarth_Arora_x3v0gw.jpg',
        company: 'Salesforce',
        position: 'SDE Intern',
    },
    {
        name: 'Tabishi Singh',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671195525/Devcomm/Wall%20of%20Fame/TabishiSingh_-_Tabishi_Singh_tq8abl.jpg',
        company: 'Expedia, Morgan Stanley',
        position: 'SDE Intern',
    },
    {
        name: 'Pawan Singh',
        image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671196210/Devcomm/Wall%20of%20Fame/IMG_7029_3_-_Pawan_Singh_rd8uzw.jpg',
        company: 'BNY Mellon (Bank of New York Mellon)',
        position: 'SDE Intern',
    }

]

export default FameList;