const Contact = () => {
    return (
      <div style={{height: '75vh'}} className={`p-4`}>
        <h1 className={`pt-5 mt-3 text-light pb-2`}>Contact Us</h1>
        <p className={`text-light fs-3 text-center pb-2`}>
            Email: <a href="mailto:nsut@devcomm.international">nsut@devcomm.international</a>
        </p>
        <p className={`text-light fs-3 text-center`}>
            Contact No.: +91-8237856897
        </p>
      </div>
    );
  };
  
  export default Contact;
  