// import ayush from '../../Assets/Images/Core-Images/AYUSH AHLAWAT_ PRESIDENT .png'
// import yashwani from '../../Assets/Images/Core-Images/yash .png'
// import pulkit from '../../Assets/Images/Core-Images/'
// import sarthak from '../../Assets/Images/Core-Images/SarthakSrivastava_VicePresident.jpg'
// import dhruvG from '../../Assets/Images/Core-Images/DhruvGarg_VIcePresident.jpg'
// import chirag from '../../Assets/Images/Core-Images/chirag_singhal_pres.jpg'

const santosh = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662580/santosh_pal_j0jbfl.png";
const dipanshu = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662577/deepanshu_bisariya_qvu0rj.jpg";
const saharsh = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662577/saharsh_tepnon.jpg";
const samriddh = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662577/samriddh_diwan_gvr0lo.jpg";
const seldon = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662577/seldon_m1jbhc.jpg";
const piyush = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662576/piyush_sinha_yggeid.jpg";
const rashid = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662576/rashid_siddiqui_t0zvah.jpg";
const shiv = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662575/tg_shivkumar_jyczcb.jpg";
const mohit = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662575/mohit_etevtz.jpg";
const kashish = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662575/kashish_jangra_c19zkz.jpg";
const ojas = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662575/ojas_sharma_yyf2ia.jpg";
const kavish = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662575/kavish_dham_flpr0k.jpg";
const atul = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662574/Atul_Kumar_me4vzu.jpg";
const ishita = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662574/ishita_srivastava_akmt43.jpg";
const ayush_a = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662574/ayush_arora_bolkqg.jpg";
const gauri = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662573/gauri_bhardwaj_ns0lll.png";
const ashutosh = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662573/ashutosh_jha_yg8jm3.jpg";
const anirudh = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662573/anirudh_prajapati_y4tlx2.jpg";
const akhil = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662572/akhil_sfjmxl.jpg";
const abhinav = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662572/abhinav_diwakar_qgoyld.jpg";
const divyansh = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662572/divyansh_sharma_mnerwe.jpg";
const divye = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662572/divye_bajaj_lhqyxu.jpg";
const yash = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662571/yash_eyya6e.jpg";
const chirag = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662570/chirag_singhal_pres_dmy9lf.jpg";
const vasu = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662570/vasu_asi0aj.jpg";
const shreya = "https://res.cloudinary.com/dop36g38s/image/upload/v1723662569/shreya_wsuluk.jpg";


const teamList = [
    {
        name: 'Chirag Singhal',
        position: 'President',
        image: chirag
    },
    {
        name: 'Anirudh Prajapati',
        position: 'President',
        image: anirudh
    },
    {
        name: 'Kavish Dham',
        position: 'Vice President',
        image: kavish
    },
    {
        name: 'Seldon Angmo',
        position: 'Vice President',
        image: seldon
    },
    {
        name: 'Akhil Marks',
        position: 'Vice President',
        image: akhil
    },
    // {
    //     name: 'Manvi Singh',
    //     position: 'Conetent Head',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651880/Manvi_Singh_Productions_Head_Content_dwxvwb.jpg'
    // },
    // {
    //     name: 'Bhavya Jain',
    //     position: 'Content Head',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651879/Bhavya_Jain_Production_Head_Content_a0wrtd.jpg'
    // },
    {
        name: 'Piyush Sinha',
        position: 'General Secretary',
        image: piyush
    },
    {
        name: 'Gauri Bhardwaj',
        position: 'General Secretary',
        image: gauri
    },
    {
        name: 'Santosh Pal',
        position: 'JT. General Secretary',
        image: santosh
    },
    {
        name: 'Saharsh',
        position: 'JT. General Secretary',
        image: saharsh
    },
    {
        name: 'Shreya Garg',
        position: 'Women in Tech Head',
        image: shreya,
    },
    {
        name: "Vasu Goel",
        position: "Operations Head",
        image: vasu,
    },
    {
        name: "Mohit Shekhawat",
        position: 'Operations Head',
        image: mohit
    },
    {
        name: 'Atul Kumar',
        position: 'Web Dev Head',
        image: atul
    },
    {
        name: 'Abhinav Diwakar',
        position: 'App Dev Head',
        image: abhinav
    },
    {
        name: 'Ojas Sharma',
        position: 'Web Dev Head',
        image: ojas
    },
    {
        name: 'Ashutosh Jha',
        position: 'App Dev Head',
        image: ashutosh
    },
    {
        name: 'Rashid Siddiqui',
        position: 'ML Head',
        image: rashid
    },
    {
        name: 'Ishita Srivastava',
        position: 'ML Head',
        image: ishita
    },
    {
        name: 'Samriddh Diwan',
        position: 'DSA Head',
        image: samriddh
    },
    {
        name: 'T.G Shivkumar',
        position: 'DSA Head',
        image: shiv
    },
    {
        name: 'Divye Bajaj',
        position: 'Video Editing Head',
        image: divye
    },
    {
        name: 'Ayush Arora',
        position: 'Graphic Design Head',
        image: ayush_a
    },
    // {
    //     name: 'Rishnak',
    //     position: 'JT. General Secretary',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651881/Rishnak_JointGenSec_dzi3eu.jpg'
    // },
    
    {
        name: 'Kashish Jangra',
        position: 'Senior Developer',
        image: kashish
    },
    // {
    //     name: 'Chirag Saini',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651879/Chirag_seniordeveloper_ehuyd5.jpg'
    // },
    // {
    //     name: 'Ahmed Jodhpuri',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651877/Ahmed_Jodhpuri_SeniorDeveloper_oonq5o.jpg'
    // },
    // {
    //     name: 'Aditya Giri',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651878/Aditya_Giri_SeniorDevloper_e8px7y.jpg'
    // },
    // {
    //     name: 'Rohan Mittal',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693651881/Rohan_Mittal_Senior_Developer_ipvv43.jpg'
    // },
    // {
    //     name: 'Kashish Jangra',
    //     position: 'Senior Execomm',
    //     image:kashish
    // },
    {
        name: 'Yash',
        position: 'Senior Execomm',
        image: yash
    },
    {
        name: 'Divyansh Sharma',
        position: 'Senior Execomm',
        image: divyansh
    },
    {
        name: 'Deepanshu Bisariya',
        position: 'Senior Execomm',
        image: dipanshu
    },
    // {
    //     name: 'Amanpreet Singh',
    //     position: 'Senior Execomm',
    //     image: 'https://res.cloudinary.com/dk96zf8ho/image/upload/v1693654448/Amanpreet_Singh_Sen_Execomm_1_cxots1.jpg'
    // },
    // {
    //     name: 'Archie Arya',
    //     position: 'Head, Women in Tech',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082898/Devcomm/photo-output_wa5m6t.jpg'
    // }
    // {
    //     name: 'Rinav Gupta',
    //     position: 'President',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082899/Devcomm/InShot_20220716_180944007_rkhvca.jpg'
    // },
    // {
    //     name: 'Madhur Ahuja',
    //     position: 'President',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082894/Devcomm/IMG_20220715_195338_itgoyj.jpg'
    // },
    // {
    //     name: 'Shirishti Jain',
    //     position: 'Vice President',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082894/Devcomm/IMG_20220718_110817_ayjqps.jpg'
    // },
    // {
    //     name: 'Vedansh Arun',
    //     position: 'Vice President',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082893/Devcomm/ff4a7292-927f-49ba-b107-a98dcb4d0de8_r6ua4e.jpg'
    // },
    // {
    //     name: 'Harshit Mittal',
    //     position: 'Vice President',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082894/Devcomm/IMG_20220718_143435_kc1tc6.jpg'
    // },
    // {
    //     name: 'Kartik Bagri',
    //     position: 'Web Dev Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671084421/Devcomm/IMG_2187_ato0tb.jpg'
    // },
    // {
    //     name: 'Vansh Gupta',
    //     position: 'Web Dev Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082897/Devcomm/Vansh_Gupta_hucwhq.jpg'
    // },
    // {
    //     name: 'Nikhil Vashisht',
    //     position: 'App Dev Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082895/Devcomm/IMG_20220718_143435_1_jlsado.jpg'
    // },
    // {
    //     name: 'Priyanshi Goel',
    //     position: 'App Dev Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082892/Devcomm/IMG_20220412_211058_282_xskkud.jpg'
    // },
    // {
    //     name: 'Amogh Garg',
    //     position: 'ML Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082895/Devcomm/IMG-20220715-WA0011__01_bnka77.jpg'
    // },
    // {
    //     name: 'Mehul Gulati',
    //     position: 'ML Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082896/Devcomm/photo_2022-07-21_22-27-10_ru8qdz.jpg'
    // },
    // {
    //     name: 'Atharav',
    //     position: 'DSA Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082894/Devcomm/IMG_20220715_192703_yxfais.jpg'
    // },
    // {
    //     name: 'Krati Jain',
    //     position: 'DSA Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082895/Devcomm/IMG_20220717_185451_z9zaiv.jpg'
    // },
    // {
    //     name: 'Archit Pandey',
    //     position: 'Production Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082895/Devcomm/IMG_20220718_121845_lfyeex.jpg'
    // },
    // {
    //     name: 'Jishnu Mittal',
    //     position: 'Graphic Design Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082893/Devcomm/E97A86F4-4721-4E6D-9FF1-32728C7D6A09_zjnekn.jpg'
    // },
    // {
    //     name: 'Joy Mary',
    //     position: 'Content Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082893/Devcomm/IMG_20220521_185227_Greatness_h6d8lk.jpg'
    // },
    // {
    //     name: 'Devansh Khandelwal',
    //     position: 'Operations Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082895/Devcomm/IMG_20220722_075145_dhqpsd.png'
    // },
    // {
    //     name: 'Pushkin Sharma',
    //     position: 'Operations Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082898/Devcomm/0C4809C8-04DE-468D-9A21-A19113846F1F_dh4dbk.jpg'
    // },
    // {
    //     name: 'Daanish Kabir Soni',
    //     position: 'Public Relations Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082898/Devcomm/20220624_174815_v90yxx.jpg'
    // },
    // {
    //     name: 'Ishan Suri',
    //     position: 'Public Relations Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082897/Devcomm/POR_IMG_ef7agj.png'
    // },
    // {
    //     name: 'Vansh Jain',
    //     position: 'Deputy Operations Head',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082896/Devcomm/WhatsApp_Image_2022-07-17_at_8.13.35_PM_Cropped_sljv9h.jpg'
    // },
    // {
    //     name: 'Pratyush Gupta',
    //     position: 'Web Project Lead',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082900/Devcomm/IOSD_rhyxa6.jpg'
    // },
    // {
    //     name: 'Rohit Sharma',
    //     position: 'Web Project Lead',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082895/Devcomm/IMG_20220716_094518_balo9t.jpg'
    // },
    // {
    //     name: 'Shubham Bhola',
    //     position: 'Web Project Lead',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082896/Devcomm/Screenshot_20220715-190234_WhatsApp_ddzxdz.jpg'
    // },
    // {
    //     name: 'Garvit Monga',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082896/Devcomm/Picsart_22-07-02_10-58-00-829_2_chlpt9.jpg'
    // },
    // {
    //     name: 'Tushar Bansal',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082898/Devcomm/IMG_20220718_130228_yzbkgi.jpg'
    // },
    // {
    //     name: 'Aditya Bhalla',
    //     position: 'Senior Developer',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082897/Devcomm/20210913_153632-02_mrpeaw.jpg'
    // },
    // {
    //     name: 'Manvi Gupta',
    //     position: 'Head, Women in Tech',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082896/Devcomm/SAVE_20220306_134805_i1tixa.jpg'
    // },
    // {
    //     name: 'Archie Arya',
    //     position: 'Head, Women in Tech',
    //     image: 'https://res.cloudinary.com/dst8xhmui/image/upload/v1671082898/Devcomm/photo-output_wa5m6t.jpg'
    // }
]

export default teamList;